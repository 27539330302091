<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            v-if="empresaLogo"
            :src="empresaLogo"
            alt="logo"
          />
        </b-link>

        <b-overlay
          :show="loadingOverlay"
          rounded="sm"
        >
          <b-card-title class="mb-2">
            {{ $t('registro.titulo') }}
          </b-card-title>

          <b-alert
            v-if="sponsor === 'null'"
            show
            variant="danger"
            class="mb-3 mt-3"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="UserIcon"
              />
              <span class="ml-25">{{ $t('registro.subtitulo') }}</span>
            </div>
          </b-alert>

          <b-alert
            v-if="sponsor && sponsor !== 'null'"
            show
            variant="warning"
            class="mb-1 mt-10"
          >
            <h4 class="alert-heading">
              {{ $t('registro.patrocionador') }}
            </h4>
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="UserIcon"
              />
              <span class="ml-25">{{ sponsor.nome }}</span>
            </div>
          </b-alert>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form
              v-if="sponsor && sponsor !== 'null'"
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- name -->
              <b-form-group
                :label="$t('registro.nome')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    name="register-name"
                    placeholder="John Carter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- name -->
              <b-form-group
                :label="$t('registro.telefone')"
                label-for="telephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telephone"
                  rules="required"
                >
                  <b-form-input
                    id="telephone"
                    v-model="telephone"
                    :state="errors.length > 0 ? false:null"
                    name="register-telephone"
                    placeholder="+55 1199999999"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <b-form-group
                :label="$t('registro.email')"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="emailUser"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                :label="$t('registro.senha')"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
              >
                {{ $t('registro.registrar') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-overlay>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('registro.temconta') }} </span>
          <b-link :to="{name:'auth-login'}">
            <span>{{ $t('registro.entraraqui') }}</span>
          </b-link>
        </b-card-text>

        <b-card-text
          v-if="sponsor"
          class="text-center mt-2"
        >
          <small> This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply</small>
        </b-card-text>
      </b-card>
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BImg, BAlert, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

import Vue from 'vue'
import axios from '@axios'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
})

export default {
  components: {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BAlert,
    BOverlay,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      emailUser: '',
      telephone: '',
      name: '',
      password: '',
      sponsor: null,
      loadingOverlay: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  setup() {
    const { empresa, empresaLogo } = useAppConfig()
    const { appName } = $themeConfig.app

    return {
      empresa,
      empresaLogo,
      appName,
    }
  },

  mounted() {
    if (this.empresa.modoTela === 'semi-dark') {
      document.body.classList.add('dark-layout')
    }
    this.fetchSponsorDetails()
  },

  beforeDestroy() {
    document.body.classList.remove('dark-layout')
  },

  methods: {

    async fetchSponsorDetails() {
      this.loadingOverlay = true
      try {
        const response = await axios.get('/publico/usuario/indicacao-codigo', { params: { codigo: this.$route.params.sponsor } })
        this.sponsor = response.data
      } catch (error) {
        this.sponsor = 'null'
      } finally {
        this.loadingOverlay = false
      }
    },

    recaptcha() {
      this.loadingOverlay = true
      const self = this
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha('register').then(token => {
          this.token = token
          useJwt.register({
            email: this.emailUser,
            telefone: this.telephone,
            nome: this.name,
            senha: this.password,
            indicacaoCodigo: this.sponsor.codigo,
            empresa: this.empresa.id,
            token: this.token,
          }).then(response => {
            useJwt.setToken(response.data.token)
            localStorage.setItem('userData', JSON.stringify(response.data))

            self.$ability.update(response.data.ability)
            this.$router.replace(getHomeRouteForLoggedInUser(response.data.perfil))
          }).catch(error => {
            let errorMessage = 'An error has occurred, please try again'

            if (error.data) {
              const errorMessages = error.data.map(err => `${err.campo}: ${err.erro}`)

              errorMessage = errorMessages.join('\n')
            }

            this.$swal({
              title: 'Erro!',
              text: errorMessage,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }).finally(() => {
            this.loadingOverlay = false
          })
        })
      })
    },

    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.recaptcha()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
